const axios = require('axios');
const config = require('../config');
const jwt = require('jsonwebtoken');

class AuthenticationHelper {
  static isAuthenticated = () => {
    let token = localStorage.getItem('token');
    let decodedToken = jwt.decode(token);

    // Handle missing AND invalid token
    if(decodedToken === null) {
      return false;
    }

    // Handle expired token
    let { exp } = decodedToken;
    var currentTimestamp = Math.round((new Date()).getTime() / 1000);

    if(currentTimestamp > exp) {
      return false;
    }

    return true;
  }

  static getUser = () => {
    try {
      let token = localStorage.getItem('token');

      return jwt.decode(token).user;
    } catch (err) {
      return '';
    }
  };

  static login = (username, password) => {
    return new Promise(async (resolve, reject) => {
      try {
        let authResponse = await axios({
          method: 'post',
          url: `${config.apiBaseUrl}/users/login`,
          auth: {
            username,
            password
          }
        });

        let {
          jwt
        } = authResponse.data;

        localStorage.setItem('token', jwt);

        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  static logout = () => {
    return new Promise(async (resolve, reject) => {
      try {
        localStorage.removeItem('token');

        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default AuthenticationHelper;