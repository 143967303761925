import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const styles = {
  mediaStyle: {
    padding: "1em",
    backgroundColor: "#F5F5F5"
  },
  isWidth128: {
    width: "128px"
  },
  link: {
    color: "#444"
  },
  dateString: {
    fontSize: ".8em",
    marginBottom: "0",
    marginTop: ".75em"
  },
  campusString: {
    fontSize: ".8em",
    fontStyle: "oblique",
    marginBottom: "0"
  },
  title: {
    fontWeight: "bold",
    marginBottom: "0"
  }
};

function handleImageError(e) {
  e.target.src = "https://via.placeholder.com/640x360?text=N/A";
}

function RecordingRecord(props) {  
  return (
    <Link to={`/recordings/${props.recording.guid}`} style={styles.link}>
      <div style={styles.mediaStyle}>
        <article className="media">
          <figure className="media-left">
            <p className="image" style={styles.isWidth128}>
              <LazyLoadImage
                src={props.recording.thumbnail_url}
                onError={handleImageError}
                alt=""
              />
            </p>
          </figure>
          <div className="media-content">
            <div className="content">
              <p style={styles.title}>
                {props.recording.title}
              </p>
              <p style={styles.campusString}>
                {props.recording.campus}
              </p>  
              <p style={styles.dateString}>
                {props.recording.formattedDate}
              </p>
            </div>
          </div>
        </article>
      </div>
    </Link>
  );
}

export default RecordingRecord;
