import AuthenticationHelper from "../../helpers/AuthenticationHelper";
import React, { Component } from "react";
import { Switch } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import FourOhFour from "../404";
import Home from "../Home";
import Recorders from "../Recorders/Recorders";
import Recordings from "../Recordings/Recordings";
import Recording from "../Recordings/Recording";
import EditRecording from "../Recordings/EditRecording";
import Monarchs from "../Monarch/Monarchs";
import Live from "../Live/Live";
import PrivateRoute from "../Common/PrivateRoute";
import Schedules from "../Schedules/Schedules";

const styles = {
  site: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  },
  siteContent: {
    flex: 1,
  },
  footer: {
    marginTop: "2em",
  },
};

class App extends Component {
  render() {
    let { username } = AuthenticationHelper.getUser();

    return (
      <div style={styles.site}>
        <div style={styles.header}>
          <Header />
        </div>

        <div style={styles.siteContent}>
          <Switch>
            <PrivateRoute path="/" exact component={Home} />
            <PrivateRoute path="/monarchs" exact component={Monarchs} />
            <PrivateRoute path="/home" exact component={Home} />
            <PrivateRoute path="/live" exact component={Live} />
            <PrivateRoute path="/recorders" exact component={Recorders} />
            <PrivateRoute path="/recordings" exact component={Recordings} />
            <PrivateRoute path="/recordings/:id" exact component={Recording} />
            <PrivateRoute
              path="/recordings/:id/edit"
              exact
              component={EditRecording}
            />
            <PrivateRoute path="/schedules" exact component={Schedules} />
            <PrivateRoute path="*" component={FourOhFour} />
          </Switch>
        </div>

        <div style={styles.footer}>
          <Footer style={styles.footer} username={username} />
        </div>
      </div>
    );
  }
}

export default App;
