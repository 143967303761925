import RecordingHelper from "../../helpers/RecordingHelper";
import React, { Component } from "react";
import moment from "moment";

import RecordingRecord from "./RecordingRecord";

const styles = {
  marginTop: {
    marginTop: "2em"
  },
  smallTitle: {
    fontSize: '.8em'
  }
};

class App extends Component {
  state = {
    filteredRecordings: [],
    isLoading: true,
    recordings: []
  };

  componentDidMount() {
    RecordingHelper.getAll()
      .then(recordings => {
        let filteredRecordings = recordings.filter((video) => {
          if (video.visible == null) return true;

          let videoVisibleValue = video.visible.data[0];

          return videoVisibleValue === 1;
        });

        this.setState({
          recordings,
          filteredRecordings,
          isLoading: false
        });
      })
      .catch(err => {
        if (err.message.indexOf("code 401") > -1) {
          this.setState({
            isLoading: false,
            isFailed: true
          });
        }
      });
  }

  handleSearchCriteriaChange = e => {
    let criteria = e.target.value.toLowerCase();

    let filteredRecordings = this.state.recordings.filter(recording => {
      let shouldReturn = false;
      
      if (recording.title.toLowerCase().indexOf(criteria) > -1) {
        shouldReturn = true;
      }

      if (recording.formattedDate.toLowerCase().indexOf(criteria) > -1) {
        shouldReturn = true;
      }

      if (shouldReturn) {
        return recording;
      }

      return null;
    });

    this.setState({
      filteredRecordings
    });
  };

  render() {
    let recordingsWithDate = this.state.filteredRecordings.map(recording => {
      let baseDate = moment(recording.date).format("YYYY-MM-DD");

      return {
        ...recording,
        baseDate
      };
    });

    let uniqueDates = [];

    recordingsWithDate.forEach(x => {
      if (!uniqueDates.includes(x.baseDate)) {
        uniqueDates.push(x.baseDate);
      }
    });

    let groups = uniqueDates.map(v => {
      let vids = recordingsWithDate.filter(x => x.baseDate === v);

      let sortedRecordings = vids.sort((a, b) => {
        let aDate = new Date(a.date);
        let bDate = new Date(b.date);

        if (a.date === b.date) {
          // Sort by name
          return a.title.localeCompare(b.title);
        } else {
          return aDate - bDate;
        }
      });

      return {
        key: v,
        date: moment.utc(v),
        recordings: sortedRecordings
      };
    });

    let sortedGroups = groups.sort((a, b) => {
      return b.date - a.date;
    });

    let content = sortedGroups.map(group => {
      let { date, recordings } = group;

      let dateString = date.format("dddd MMMM Do, YYYY");

      let recordingContent = recordings.map(recording => {
        return (
          <div key={recording.guid} className="column is-half">
            <RecordingRecord recording={recording} />
          </div>
        );
      });

      return (
        <div key={group.key}>
          <h1 className="title">{dateString}</h1>
          <div className="columns is-multiline">{recordingContent}</div>
          <br />
        </div>
      );
    });

    let warningMessage = 'There are no recordings available at this time.';

    // Change warning message if there are no search results
    if(this.state.recordings !== null) {
      if(this.state.recordings.length > 0) {
        warningMessage = 'There are no recordings that match your search criteria.';
      }
    }

    let noRecordingWarning = (
      <div className="container" style={styles.marginTop}>
        <div className="notification is-warning">
          { warningMessage }
        </div>
      </div>
    );

    if (this.state.isFailed) {
      noRecordingWarning = (
        <div className="container" style={styles.marginTop}>
          <div className="notification is-danger">
            There was a problem retrieving recordings. Please log out and try
            again.
          </div>
        </div>
      );
    }

    let loading = (
      <div className="container" style={styles.marginTop}>
        <div className="notification is-primary">Loading...</div>
      </div>
    );

    return (
      <div>
        <section className="hero is-dark">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">Recordings</h1>
              <h2 className="subtitle">Previously recorded events</h2>
            </div>
          </div>
        </section>

        <div className="container" style={styles.marginTop}>
          <div className="field">
            <label className="label">Search</label>
            <div className="control">
              <input
                onChange={this.handleSearchCriteriaChange}
                className="input"
                type="text"
                placeholder="Title or Date"
              />
            </div>
          </div>
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <p style={styles.smallTitle}>
                  TOTAL: { this.state.recordings.length }
                </p>
              </div>
            </div>
            <div className="level-left">
              <div className="level-item">
                <p style={styles.smallTitle}>
                  SEARCH RESULTS: { this.state.filteredRecordings.length }
                </p>
              </div>
            </div>
          </div>
        </div>

        {this.state.isLoading && loading}

        {!this.state.isLoading &&
          sortedGroups.length === 0 &&
          noRecordingWarning}

        <div className="container" style={styles.marginTop}>
          {content}
        </div>
      </div>
    );
  }
}

export default App;
