import React, { Component } from "react";
import RecorderHelper from "../../helpers/RecorderHelper";

const styles = {
  body: {
    height: "100%"
  },
  marginTop: {
    marginTop: "2em"
  },
  title: {
    fontSize: "1.2em",
    fontWeight: "bold",
    marginBottom: ".5em"
  },
  streamToggleButton: {
    marginTop: ".75em"
  },
  icon: {
    fontSize: ".8em",
    padding: ".25em"
  },
  timemark: {
    display: "block",
    fontFamily: "Courier",
    fontWeight: "bold",
    fontSize: ".9em"
  },
  status: {
    fontSize: ".8em"
  },
  buttonText: {
    textTransform: 'uppercase',
    fontSize: '.8em'
  }
};

class Recorder extends Component {
  state = {
    failed: false,
    recorder: null,
    isLoading: true,
    buttonPause: false
  };

  handleStartRecording(e) {
    RecorderHelper.start(this.props.id);
  }

  handleStopRecording(e) {
    RecorderHelper.stop(this.props.id);
  }

  getStatus = status => {
    if (status === "recording") {
      return (
        <span style={styles.status} className="has-text-danger">
          <i className="fas fa-circle-notch fa-spin" /> {status.toUpperCase()}
        </span>
      );
    } else {
      return <span style={styles.status}>{status.toUpperCase()}</span>;
    }
  };

  getTimemark = (status, timemark) => {
    if (timemark === null || status !== "recording") {
      return "00:00:00.00";
    } else {
      return timemark;
    }
  };

  getWaitButton = text => {
    return (
      <button style={styles.streamToggleButton} className="button is-dark">
        <i style={styles.icon} className="fas fa-spinner fa-spin" /> <span style={styles.buttonText}>{text}</span>
      </button>
    );
  };

  recordingButton = status => {
    let startButton = (
      <button
        style={styles.streamToggleButton}
        className="button is-success"
        onClick={this.handleStartRecording.bind(this)}
      >
        <span style={styles.buttonText}>Start</span>
      </button>
    );

    let stopButton = (
      <button
        style={styles.streamToggleButton}
        className="button is-danger"
        onClick={this.handleStopRecording.bind(this)}
      >
        <span style={styles.buttonText}>Stop</span>
      </button>
    );

    switch (status) {
      case "recording":
        return stopButton;
      case "stopped":
        return startButton;
      default:
        return this.getWaitButton(status.toUpperCase());
    }
  };

  render() {
    let title = this.props.title;
    let { status, timemark } = this.props.recorder.recording;

    return (
      <div className="notification" style={styles.body}>
        {this.props.hideHeader !== true && (
          <p style={styles.title}>{title}</p>
        )}

        <p>{this.getStatus(status)}</p>
        <span style={styles.timemark}>
          {this.getTimemark(status, timemark)}
        </span>

        {this.recordingButton(status)}
      </div>
    );
  }
}

export default Recorder;
