const axios = require("axios");
const config = require("../config");

export const getSchedules = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: "post",
        url: `${config.apiBaseUrl}/graphql`,
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        data: {
          query: `
          query {
            getSchedules {
              id
              monarch_id
              source_id
              monarch {
                id
                guid
                title
              }
              source {
                id
                guid
                title
                campus
              }
              title
              campus
              notes
              start_monarch
              start_recording
              stop_monarch
              stop_recording
              active
            }
          }
        `,
        },
      });

      resolve(response.data.data.getSchedules);
    } catch (err) {
      reject(err);
    }
  });
};
