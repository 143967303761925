import AuthenticationHelper from "../../helpers/AuthenticationHelper";
import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import Logo from "../../assets/logo-black.svg";

const styles = {
  smallMarginRight: {
    marginRight: ".25em"
  }
};

class Header extends Component {
  state = {
    redirectToLogin: false,
    redirectToMonarchs: false,
    showNavbar: false,
    showRecordingAndMonarchControl: false,
    showLive: false,
    showHome: true,
    showRecordings: true,
    showLogout: true,
    showReload: true,
    forcedToggle: false
  };

  handleReload = e => {
    // Force a render without state change...
    window.location.reload();
  };

  handleLogout = async e => {
    e.preventDefault();

    await AuthenticationHelper.logout();

    this.setState({
      redirectToLogin: true
    });
  };

  handleToggleNavbar = e => {
    e.preventDefault();

    this.setState({
      showNavbar: !this.state.showNavbar
    });
  };

  handleCloseNavbar = () => {
    this.setState({
      showNavbar: false
    });
  };

  forceToggle() {
    // Set initial state for header components to display
    let showHome = this.state.showHome;
    let showRecordings = this.state.showRecordings;
    let showLogout = this.state.showLogout;
    let showReload = this.state.showReload;
    let forcedToggle = this.state.forcedToggle;

    if (typeof this.props.showHome === "boolean") {
      showHome = this.props.showHome;
    }

    if (typeof this.props.showRecordings === "boolean") {
      showRecordings = this.props.showRecordings;
    }

    if (typeof this.props.showLogout === "boolean") {
      showLogout = this.props.showLogout;
    }

    if (typeof this.props.showReload === "boolean") {
      showReload = this.props.showReload;
    }

    if (typeof this.props.forcedToggle === "boolean") {
      forcedToggle = this.props.forcedToggle;
    }

    this.setState({
      showHome,
      showRecordings,
      showLogout,
      showReload,
      forcedToggle
    })
  }

  componentWillMount() {
    this.forceToggle();
  }

  componentDidMount() {
    // Show recording control, conditionally
    let user = AuthenticationHelper.getUser();

    if (user !== "" && !this.state.forcedToggle) {
      let showRecordingAndMonarchControl = user.permissions.canControl === true;
      let showLive = user.permissions.canSeeLive === true;

      try {
        this.setState({
          showRecordingAndMonarchControl,
          showLive
        });
      } catch (err) {}
    }
  }

  render() {
    if (this.state.redirectToLogin === true) {
      return <Redirect to="/login" />;
    }

    if (this.state.redirectToMonarchs === true) {
      return <Redirect to="/monarchs" />;
    }

    return (
      <nav
        className="navbar has-background-grey-lighter"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link
            className="navbar-item"
            to="/home"
            onClick={this.handleCloseNavbar}
          >
            <img src={Logo} width="112" height="28" alt="" />
          </Link>

          <a
            href="/"
            onClick={this.handleToggleNavbar}
            role="button"
            className={
              "navbar-burger burger" +
              (this.state.showNavbar ? "is-active" : "")
            }
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>

        <div
          id="navbarBasicExample"
          className={"navbar-menu" + (this.state.showNavbar ? "is-active" : "")}
        >
          <div className="navbar-start">
            {this.state.showHome && (
              <Link
                className="navbar-item"
                to="/home"
                onClick={this.handleCloseNavbar}
              >
                Home
              </Link>
            )}
            {this.state.showLive && (
              <Link
                className="navbar-item"
                to="/live"
                onClick={this.handleCloseNavbar}
              >
                Live
              </Link>
            )}
            {this.state.showRecordings && (
              <Link
                className="navbar-item"
                to="/recordings"
                onClick={this.handleCloseNavbar}
              >
                Recordings
              </Link>
            )}
            {this.state.showRecordingAndMonarchControl && (
              <Link
                className="navbar-item"
                to="/schedules"
                onClick={this.handleCloseNavbar}
              >
                Schedules
              </Link>
            )}
          </div>

          <div className="navbar-end">
            {this.state.showRecordingAndMonarchControl && (
              <div className="navbar-item">
                <Link
                  className="button is-danger"
                  to="/recorders"
                  onClick={this.handleCloseNavbar}
                  style={styles.smallMarginRight}
                >
                  <i className="fas fa-dot-circle" />
                </Link>
                <Link
                  className="button is-info"
                  to="/monarchs"
                  onClick={this.handleCloseNavbar}
                  style={styles.smallMarginRight}
                >
                  <i className="fas fa-server" />
                </Link>
              </div>
            )}
            <div className="navbar-item">
              <div className="buttons">
                {this.state.showReload && (
                  <button
                    onClick={this.handleReload}
                    className="button is-light"
                  >
                    <i className="fas fa-sync-alt" />
                  </button>
                )}
                {this.state.showLogout && (
                  <button
                    className="button is-light"
                    onClick={this.handleLogout}
                  >
                    <i
                      className="fas fa-sign-out-alt"
                      style={styles.smallMarginRight}
                    />
                    Log out
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Header;
