import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import RouteChange from './RouteChange';

import App from "./App";
import Login from "./Login";
import LiveMultiView from '../Live/LiveMultiView';
import PrivateRoute from '../Common/PrivateRoute';
import PublicRecording from '../Recordings/PublicRecording';

class Base extends Component {
  render() {
    return (
      <Router>
        <div>
          <RouteChange />
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/public/:id" exact component={PublicRecording} />
            <PrivateRoute path="/live/multiview" exact component={LiveMultiView} />
            <Route path="/" component={App} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default Base;
