const axios = require('axios');
const config = require('../config');

class RecorderHelper {
  static getAll = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let token = localStorage.getItem('token');

        let sources = await axios({
          method: 'get',
          url: `${config.apiBaseUrl}/recorders`,
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        // Sort by title
        let sortedSources = sources.data
          .filter(x => x !== null)
          .sort((a, b) => a.title.localeCompare(b.title));

        resolve(sortedSources);
      } catch (err) {
        reject(err);
      }
    });
  }

  static getOne = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        let sources = await RecorderHelper.getAll();
        let source = sources.filter(x => x.guid === id)[0];

        let token = localStorage.getItem('token');

        let recording = await axios({
          method: 'get',
          url: `${config.apiBaseUrl}/recorders/${source.guid}`,
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        let rec = {
          ...source,
          recording: recording.data
        };

        resolve(rec)
      } catch (err) {
        reject(err);
      }
    });
  }

  static start = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        let token = localStorage.getItem('token');
        
        let recorder = await axios({
          method: 'post',
          url: `${config.apiBaseUrl}/recorders/${id}?action=start`,
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        resolve(recorder.data);
      } catch (err) {
        reject(err);
      }
    });
  }

  static stop = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        let token = localStorage.getItem('token');

        let recorder = await axios({
          method: 'post',
          url: `${config.apiBaseUrl}/recorders/${id}?action=stop`,
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        resolve(recorder.data);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default RecorderHelper;