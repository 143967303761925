import AuthenticationHelper from "../helpers/AuthenticationHelper";
import React, { Component } from "react";

const styles = {
  marginTop: {
    marginTop: '2em'
  }
}

class App extends Component {
  state = {
    showLive: false
  };

  componentDidMount() {
    // Show recording control, conditionally
    let user = AuthenticationHelper.getUser();

    let showLive = user.permissions.canSeeLive === true;

    try {
      this.setState({
        showLive
      });
    } catch (err) {}
  }

  render() {
    return (
      <div>
        <section className="hero is-dark">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">EIDON Video Recording Service</h1>
            </div>
          </div>
        </section>

        <div className="container" style={styles.marginTop}>
          <div className="content notification">
            <p>
              Welcome to the EIDON Video Recording Service.  Here are a few quick notes before you get started:
            </p>
            <ul>
              <li>EIDON is designed to work best with <strong>Chrome</strong>, however, it should also work well with other browsers.</li>
              { this.state.showLive && (
                <li>The <strong>Live</strong> section displays a list of Live views, if any are available.</li>
              )}
              <li>The <strong>Recordings</strong> section shows a list of searchable recordings.</li>
              <li>If you want to scrub currently recording video, go to the <strong>Recordings</strong> section in <strong>Chrome</strong>.  You can scrub live recordings on that page.</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
