const axios = require('axios');
const config = require('../config');

function doesExist(url) {
  const TIMEOUT = 2000;

  return new Promise(async (resolve, reject) => {
    try {
      let response = await axios({
        method: 'get',
        url,
        timeout: TIMEOUT
      });

      if (response.status === 200) {
        resolve(true);
      } else {
        resolve(false);
      }
    } catch (err) {
      resolve(false);
    }
  });
};

class LiveHelper {
  static getAll = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let token = localStorage.getItem('token');

        let videos = await axios({
          method: 'get',
          url: `${config.apiBaseUrl}/sources`,
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        let vids = videos.data.map(video => {
          return new Promise(async (resolve, reject) => {
            try {
                let doesPublicExist = await doesExist(video.public_url);

                if (doesPublicExist) {
                  return resolve({
                    ...video,
                    url: video.public_url
                  });
                }
              resolve(null);
            } catch (err) {
              reject(err);
            }
          });
        });

        let accessibleVideos = await Promise.all(vids);

        let accessibleVideosNotNull = accessibleVideos.filter(element => {
          return element !== null;
        });

        let visible = accessibleVideosNotNull.filter((x) => x.view_live === 1);

        // Sort by title
        let sortedVideos = visible
          .filter(x => x !== null)
          .sort((a, b) => a.title.localeCompare(b.title));

        resolve(sortedVideos);
      } catch (err) {
        reject(err);
      }
    });
  }

  static getOne = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        let videos = await LiveHelper.getAll();

        let video = videos.filter(x => x.guid === id);

        resolve(video[0]);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default LiveHelper;