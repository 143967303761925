import React from "react";

const styles = {
  small: {
    fontSize: '.8em'
  }
};

let audioOn = <i className="fas fa-volume-up" />;
let audioOff = <i className="fas fa-volume-off" />;
let videoOn = <i className="fas fa-video" />;
let videoOff = <i className="fas fa-video-slash" />;

function MonarchInputIndicator(props) {
  let { status, type } = props;

  let statusComponent = "";

  switch (status) {
    case "No audio input":
      statusComponent = <p style={styles.small} className="has-text-danger">{audioOff} {status}</p>;
      break;
    case "No video input":
      statusComponent = <p style={styles.small} className="has-text-danger">{videoOff} {status}</p>;
      break;
    default:
      switch(type) {
        case "audio":
          statusComponent = <p style={styles.small} className="has-text-success">{audioOn} {status}</p>;
          break;
        case "video":
          statusComponent = <p style={styles.small} className="has-text-success">{videoOn} {status}</p>;
          break;
        default:
          break;
      }
      break;
  }

  return <div>{statusComponent}</div>;
}

export default MonarchInputIndicator;
