import config from '../../config';
import LiveHelper from "../../helpers/LiveHelper";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Player from "../Player";
import utils from "../../utils";

const styles = {
  marginTop: {
    marginTop: "2em"
  },
  smallMarginRight: {
    marginRight: ".25em"
  }
};

class Live extends Component {
  state = {
    videos: [],
    isLoading: true
  };

  refreshVideos() {
    LiveHelper.getAll()
      .then(async videos => {
        this.setState({
          videos,
          isLoading: false
        });
      })
      .catch(err => {
        if (err.message.indexOf("code 401") > -1) {
          this.setState({
            isLoading: false,
            isFailed: true
          });
        }
      });
  }

  checkForNewVideos() {
    // Check for new videos, and refresh if the counts are different
    LiveHelper.getAll()
      .then(async videos => {
        let currentVideos = this.state.videos.map(video => {
          return video.guid;
        });

        let newVideos = videos.map(video => {
          return video.guid;
        });

        let areEqual = utils.arraysEqual(currentVideos, newVideos);

        if (!areEqual) {
          this.setState({
            videos,
            isLoading: false
          });
        }
      })
      .catch(err => {
        if (err.message.indexOf("code 401") > -1) {
          this.setState({
            isLoading: false,
            isFailed: true
          });
        }
      });
  }

  componentDidMount() {
    this.refreshVideos();

    this.interval = setInterval(() => {
      this.checkForNewVideos();
    }, config.liveVideoRefreshCheck);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    let sourceComponents = this.state.videos.map(video => {
      return (
        <div key={video.guid} className="column is-half">
          <Player
            key={video.guid}
            title={video.title}
            url={video.url}
            type={video.type}
            muted={true}
            isRecording={false}
          />
        </div>
      );
    });

    let noVideoWarning = (
      <div className="container" style={styles.marginTop}>
        <div className="notification is-warning">
          There are no live views available at this time.
        </div>
      </div>
    );

    if (this.state.isFailed) {
      noVideoWarning = (
        <div className="container" style={styles.marginTop}>
          <div className="notification is-danger">
            There was a problem retrieving live views. Please log out and try
            again.
          </div>
        </div>
      );
    }

    let loading = (
      <div className="container" style={styles.marginTop}>
        <div className="notification is-primary">Loading...</div>
      </div>
    );

    return (
      <div>
        <section className="hero is-dark">
          <div className="hero-body">
            <div className="container">
              <div className="level">
                <div className="level-left">
                  <div>
                    <h1 className="title">Live Videos</h1>
                    <h2 className="subtitle">Campus Live View</h2>
                  </div>
                </div>
                <div className="level-right">
                  <Link to="/live/multiview?cols=3" className="button is-dark">
                    <i
                      className="fas fa-expand-arrows-alt"
                      style={styles.smallMarginRight}
                    />
                    Multi View (3 col)
                  </Link>
                  <Link to="/live/multiview?cols=2" className="button is-dark">
                    <i
                      className="fas fa-expand-arrows-alt"
                      style={styles.smallMarginRight}
                    />
                    Multi View (2 col)
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {this.state.isLoading && loading}

        {!this.state.isLoading &&
          this.state.videos.length === 0 &&
          noVideoWarning}

        <div className="container" style={styles.marginTop}>
          <div className="columns is-multiline">{sourceComponents}</div>
        </div>
      </div>
    );
  }
}

export default Live;
