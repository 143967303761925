import RecorderHelper from "../../helpers/RecorderHelper";
import Recorder from './Recorder';
import React, { Component } from "react";

const styles = {
  marginTop: {
    marginTop: "2em"
  }
};

class Recorders extends Component {
  state = {
    recorders: []
  };

  getStatus() {
    RecorderHelper.getAll()
      .then(async recorders => {
        this.setState({
          recorders
        });
      })
      .catch(err => {
        if (err.message.indexOf("code 401") > -1) {
          this.setState({
            isLoading: false,
            isFailed: true
          })
        }
      });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidMount() {
    this.getStatus();

    this.interval = setInterval(() => {
      this.getStatus();
    }, 2000);
  }

  render() {
    let noRecordersWarning = "";

    if (this.state.isFailed) {
      noRecordersWarning = (
        <div className="container" style={styles.marginTop}>
          <div className="notification is-danger">
            There was a problem retrieving Recorders.  Please log out and try again.
          </div>
        </div>
      );
    }

    let recorderComponents = this.state.recorders.map(recorder => {
      return (
        <div key={recorder.guid} className="column is-one-third">
          {/* <Recorder id={recorder.guid} title={recorder.title} /> */}
          <Recorder id={recorder.guid} recorder={recorder} title={recorder.title} />
        </div>
      );
    });

    return (
      <div>
        <section className="hero is-dark">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">Recorder Control</h1>
              <h2 className="subtitle">Manage Recorders</h2>
            </div>
          </div>
        </section>

        {noRecordersWarning}

        <div className="container" style={styles.marginTop}>
          <div className="columns is-multiline">{recorderComponents}</div>
        </div>
      </div>
    );
  }
}

export default Recorders;
