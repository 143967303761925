import RecordingHelper from "../helpers/RecordingHelper";
import React, { Component } from "react";
import Hls from "hls.js";
import Plyr from "plyr";
import "plyr/dist/plyr.css";

const styles = {
  video: {
    width: "100%",
  },
  videoContainer: {
    position: "relative",
  },
  label: {
    zIndex: "999",
    position: "absolute",
    left: "0",
    right: "0",
    bottom: "0",
    marginBottom: "2em",
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  labelText: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    color: "white",
    fontSize: "1.2em",
    paddingLeft: "1em",
    paddingRight: "1em",
    paddingTop: ".25em",
    paddingBottom: ".25em",
    borderRadius: ".25em",
  },
  recordingLabel: {
    zIndex: "999",
    position: "absolute",
    right: "0",
    top: "0",
    marginTop: "1em",
    marginLeft: "auto",
    marginRight: "1em",
    textAlign: "center",
  },
  recordingLabelText: {
    color: "red",
    fontSize: "0.8em",
    paddingLeft: "1em",
    paddingRight: "1em",
    paddingTop: ".25em",
    paddingBottom: ".25em",
    borderRadius: ".25em",
  },
};

class App extends Component {
  componentWillUnmount() {
    // Try to destroy the video players so they're not playing in the background
    try {
      this.hls.stopLoad();
      this.player.destroy();
    } catch (err) {}
  }

  componentDidMount() {
    let { muted, type, url } = this.props;

    this.player = this.refs.playerRef;

    switch (type) {
      case "mp4":
        this.player.src = url;
        break;
      case "m3u8":
        if (Hls.isSupported()) {
          this.hls = new Hls({
            maxBufferLength: 240,
          });
          this.hls.loadSource(url);
          this.hls.attachMedia(this.player);

          new Plyr(this.player, {
            fullscreen: {
              iosNative: true,
            },
          });

          this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
            if (this.props.at !== undefined) {
              console.log(this.props.at + "!!!");

              let atAsInt = parseInt(this.props.at);

              this.player.currentTime = atAsInt;
            }

            this.player.play();
          });
        } else {
          this.player.src = url;
        }
        break;
      default:
        break;
    }

    if (muted) {
      this.player.volume = 0;
    } else {
      this.player.volume = 1;
    }
  }

  handleCopyLinkAction(e) {
    // Make public link, 7 days
    RecordingHelper.createPublicLink(this.props.guid)
      .then((newGuid) => {
        let newUrl = `https://eidon.newhopechurch.org/public/${newGuid}?at=${this.player.currentTime}`;

        window.open(newUrl, "_blank");
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  handleDownloadLinkAction(e) {
    // Make public link, 7 days
    RecordingHelper.queueDownload(this.props.id)
      .then((newGuid) => {
        alert('Downloading. An email will be sent to you once the download has been completed!');
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  render() {
    const label = (
      <p style={styles.label}>
        <span style={styles.labelText}>{this.props.title}</span>
      </p>
    );

    const recordingLabel = (
      <p style={styles.recordingLabel}>
        <span style={styles.recordingLabelText}>&#9679; REC</span>
      </p>
    );

    return (
      <div style={styles.videoContainer}>
        {this.props.title !== undefined && label}
        {this.props.isRecording === true && recordingLabel}
        <video
          id={this.props.guid}
          ref="playerRef"
          style={styles.video}
          autoPlay
          autopause="false"
          controls
          crossOrigin="true"
          playsInline
          muted={this.props.muted}
        />

        {this.props.showCopyLinks && (
          <p>
            <br />
            <ul>
              <li>
                <a onClick={this.handleCopyLinkAction.bind(this)}>
                  Jump to public link to this position in the video (expires in
                  7 days)
                </a>
              </li>
            </ul>
          </p>
        )}

        {this.props.showDownloadLink && this.props.allowDownload && (
          <p>
            <br />
            <ul>
              <li>
                <a onClick={this.handleDownloadLinkAction.bind(this)}>Download</a>
              </li>
            </ul>
          </p>
        )}
      </div>
    );
  }
}

export default App;
