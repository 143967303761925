import React from "react";

function Footer(props) {
  const style = {
    textAlign: "center",
    width: '100%',
    backgroundColor: '#eee',
    padding: '1em',
    fontSize: '.8em',
    clear: 'both'
  };

  return (
    <div style={style}>
      eidó: be aware, behold, consider, perceive &bull; <b>{props.username}</b>
    </div>
  );
}

export default Footer;
