import AuthenticationHelper from "../../helpers/AuthenticationHelper";
import RecordingHelper from "../../helpers/RecordingHelper";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Player from "../Player";
import qs from "query-string";

const styles = {
  video: {
    width: "100%",
  },
  marginTop: {
    marginTop: "2em",
  },
};

class App extends Component {
  state = {
    video: {},
    showEdit: false,
  };

  componentWillMount() {
    let id = this.props.match.params.id;

    let user = AuthenticationHelper.getUser();

    if (user !== "") {
      try {
        this.setState({
          showEdit: user.permissions.canControl === true,
        });
      } catch (err) {}
    }

    RecordingHelper.getOne(id)
      .then((video) => {
        this.setState({
          video,
        });
      })
      .catch((err) => {
        if (err.message.indexOf("code 401") > -1) {
          this.setState({
            isLoading: false,
            isFailed: true,
          });
        }
      });
  }

  render() {
    let at = qs.parse(this.props.location.search).at;

    let user = AuthenticationHelper.getUser();

    let canCreatePublicLink = user.permissions.canCreatePublicLink === true;
    let canDownload = user.permissions.canDownload === true;

    let {
      id,
      formattedDate: date,
      guid,
      title,
      type,
      video_url: url,
        allow_download
    } = this.state.video;

    let allowDownload = allow_download === 1;

    let noVideoWarning = "";

    if (this.state.isFailed) {
      title = "";
      date = "";

      noVideoWarning = (
        <div className="container" style={styles.marginTop}>
          <div className="notification is-danger">
            There was a problem retrieving this video. Please log out and try
            again.
          </div>
        </div>
      );
    }

    let isVideoObjectEmpty =
      Object.keys(this.state.video).length === 0 &&
      this.state.video.constructor === Object;

    return (
      <div>
        <section className="hero is-dark">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-two-thirds">
                  <h1 className="title">{title}</h1>
                  <h2 className="subtitle">{date}</h2>
                </div>
                <div className="column is-one-third">
                  {this.state.showEdit && (
                    <Link
                      to={`/recordings/${guid}/edit`}
                      className="is-pulled-right"
                    >
                      <i class="far fa-edit"></i> Edit
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {noVideoWarning}

        {!this.state.isFailed && (
          <div className="container" style={styles.marginTop}>
            {!isVideoObjectEmpty && (
              <Player
                key={guid}
                id={id}
                guid={guid}
                url={url}
                type={type}
                at={at}
                showCopyLinks={canCreatePublicLink}
                showDownloadLink={canDownload}
                allowDownload={allowDownload}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

export default App;
