import RecordingHelper from "../../helpers/RecordingHelper";
import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

export default function EditRecording() {
  let { id } = useParams();
  let history = useHistory();

  let [title, setTitle] = useState("");
  let [campus, setCampus] = useState("");

  useEffect(() => {
    RecordingHelper.getOne(id).then((video) => {
      setTitle(video.title);
      setCampus(video.campus);
    });
  }, [id]);

  function saveChanges() {
    // TODO: The actual save
    RecordingHelper.update(id, {
      title,
      campus,
    }).then(() => {
      history.push(`/recordings/${id}`);
    });
  }

  return (
    <div>
      <section className="hero is-dark">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">Edit Recording</h1>
          </div>
        </div>
      </section>

      <br />

      <div className="container">
        {/* Title */}
        <div className="field">
          <label className="label">Title</label>
          <div className="control">
            <input
              className="input"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </div>

        {/* Campus */}
        <div className="field">
          <label className="label">Campus</label>
          <div className="control">
            <input
              className="input"
              type="text"
              value={campus}
              onChange={(e) => setCampus(e.target.value)}
            />
          </div>
        </div>

        <div className="field is-grouped">
          <div className="control">
            <button className="button is-success" onClick={saveChanges}>
              Save
            </button>
          </div>
          <div className="control">
            <Link to={`/recordings/${id}`} className="button is-link is-danger">
              Cancel
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
