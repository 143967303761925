import AuthenticationHelper from "./AuthenticationHelper";
import axios from 'axios';
const config = require('../config');

class LogHelper {
  static logRoute = (route) => {
    return new Promise(async (resolve, reject) => {
      try {
        let token = localStorage.getItem('token');
        let {
          username
        } = AuthenticationHelper.getUser();

        if (token !== null) {
          await axios({
            method: 'post',
            url: `${config.apiBaseUrl}/log/pageloads`,
            headers: {
              Authorization: `Bearer ${token}`
            },
            data: {
              username,
              route
            }
          });
        }

        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default LogHelper;