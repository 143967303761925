import AuthenticationHelper from '../../helpers/AuthenticationHelper';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = AuthenticationHelper.isAuthenticated();

  if (!isAuthenticated) {
    AuthenticationHelper.logout();
  }

  return (
    <Route {...rest} render={(props) => (
      isAuthenticated === true
      ? <Component {...props} />
      : <Redirect to='/login' />
    )} />
)}


export default PrivateRoute;