const axios = require('axios');
const config = require('../config');

class MonarchHelper {
  static getAll = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let token = localStorage.getItem('token');

        let monarchs = await axios({
          method: 'get',
          url: `${config.apiBaseUrl}/monarchs`,
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        resolve(monarchs.data);
      } catch (err) {
        reject(err);
      }
    });
  }

  static getOne = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        let token = localStorage.getItem('token');

        let monarch = await axios({
          method: 'get',
          url: `${config.apiBaseUrl}/monarchs/${id}`,
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        resolve(monarch.data);
      } catch (err) {
        reject(err);
      }
    });
  }

  static start = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        let token = localStorage.getItem('token');

        let monarch = await axios({
          method: 'post',
          url: `${config.apiBaseUrl}/monarchs/${id}?action=start`,
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        resolve(monarch.data);
      } catch (err) {
        reject(err);
      }
    });
  }

  static stop = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        let token = localStorage.getItem('token');

        let monarch = await axios({
          method: 'post',
          url: `${config.apiBaseUrl}/monarchs/${id}?action=stop`,
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        resolve(monarch.data);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default MonarchHelper;