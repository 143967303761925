import React, { Component } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { getSchedules } from "../../helpers/SchedulesHelper";

import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import parser from "cron-parser";

const localizer = momentLocalizer(moment);

const styles = {
  marginTop: {
    marginTop: "2em",
  },
  smallTitle: {
    fontSize: ".8em",
  },
};

class Schedules extends Component {
  state = {
    isLoading: true,
    schedules: [],
    events: [],
  };

  componentDidMount() {
    let opts = {
      currentDate: new Date().setHours(0, 0, 0, 0),
      tz: "America/New_York",
    };

    getSchedules().then((schedules) => {
      let activeSchedules = schedules.filter((x) => x.active === true);

      let events = [];

      activeSchedules.forEach((x) => {
        let type = "View";
        let start = null;
        let end = null;

        if (
          x.start_monarch !== "" &&
          x.start_monarch !== undefined &&
          x.start_monarch !== null &&
          x.stop_monarch !== "" &&
          x.stop_monarch !== undefined &&
          x.stop_monarch !== null
        ) {
          type = "View";
          start = new Date(
            parser.parseExpression(x.start_monarch, opts).next()
          );
          end = new Date(parser.parseExpression(x.stop_monarch, opts).next());

          events.push({
            type,
            title: `${type} ${x.title}`,
            allDay: false,
            start,
            end,
          });
        }

        if (
          x.start_recording !== "" &&
          x.start_recording !== undefined &&
          x.start_recording !== null &&
          x.stop_recording !== "" &&
          x.stop_recording !== undefined &&
          x.stop_recording !== null
        ) {
          type = "Record";
          start = new Date(
            parser.parseExpression(x.start_recording, opts).next()
          );
          end = new Date(parser.parseExpression(x.stop_recording, opts).next());

          events.push({
            type,
            title: `${type} ${x.title}`,
            allDay: false,
            start,
            end,
          });
        }
      });

      this.setState({ events });
    });
  }

  eventStyleGetter(event, start, end, isSelected) {
    let backgroundColor = `#${event.hexColor}`;
    let color = `black`;

    switch (event.type) {
      case "Record":
        backgroundColor = "#FF3860";
        color = "white";
        break;
      case "View":
        backgroundColor = "#24D160";
        break;
      default:
        break;
    }

    let style = {
      backgroundColor,
      color,
      opacity: 0.9,
      borderColor: "gainsboro",
    };

    return { style };
  }

  render() {
    return (
      <div>
        <section className="hero is-dark">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">Schedules</h1>
              <h2 className="subtitle">Automatically Scheduled Events</h2>
            </div>
          </div>
        </section>

        <div className="container" style={styles.marginTop}>
          <Calendar
            localizer={localizer}
            events={this.state.events}
            defaultDate={new Date()}
            defaultView="agenda"
            startAccessor="start"
            endAccessor="end"
            style={{ height: "100vh" }}
            eventPropGetter={this.eventStyleGetter}
          />
        </div>
      </div>
    );
  }
}

export default Schedules;
