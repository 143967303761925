const axios = require("axios");
const config = require("../config");
const moment = require("moment");

class RecordingHelper {
  static getAll = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let token = localStorage.getItem("token");

        let videos = await axios({
          method: "get",
          url: `${config.apiBaseUrl}/recordings`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        let vids = videos.data.map((video) => {
          let formattedDate = moment
            .utc(video.date)
            .format("MM/DD/YYYY hh:mm A");

          return {
            ...video,
            formattedDate,
          };
        });

        resolve(vids);
      } catch (err) {
        reject(err);
      }
    });
  };

  static getOne = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        let videos = await RecordingHelper.getAll();

        let video = videos.filter((x) => x.guid === id);

        resolve(video[0]);
      } catch (err) {
        reject(err);
      }
    });
  };

  static createPublicLink = (guid) => {
    return new Promise(async (resolve, reject) => {
      try {
        let token = localStorage.getItem("token");

        let response = await axios({
          method: "post",
          url: `${config.apiBaseUrl}/recordings/createpublic`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { guid },
        });

        resolve(response.data.guid);
      } catch (err) {
        reject(err);
      }
    });
  };

  static queueDownload = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        let token = localStorage.getItem("token");

        await axios({
          method: "post",
          url: `${config.apiBaseUrl}/recordings/queuedownload`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { id },
        });

        resolve();
      } catch (err) {
        reject(err);
      }
    });
  };

  static update = (guid, recording) => {
    return new Promise(async (resolve, reject) => {
      try {
        let token = localStorage.getItem("token");

        await axios({
          method: "put",
          url: `${config.apiBaseUrl}/recordings/${guid}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: recording,
        });

        resolve();
      } catch (err) {
        reject(err);
      }
    });
  };

  static getOnePublic = (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        let recordings = await axios({
          method: "get",
          url: `${config.apiBaseUrl}/publicrecordings/${id}`,
        });

        let recording = recordings.data;

        let formattedDate = moment
          .utc(recording.date)
          .format("MM/DD/YYYY hh:mm A");

        if (recording !== "") {
          resolve({
            ...recording,
            formattedDate,
          });
        } else {
          resolve(null);
        }
      } catch (err) {
        reject(err);
      }
    });
  };
}

export default RecordingHelper;
