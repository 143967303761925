import LogHelper from "../../helpers/LogHelper";
import { Component } from "react";
import { withRouter } from "react-router";

class RouteChange extends Component {
  componentDidMount() {
    this.routeChanged();
  }

  componentDidUpdate(prevProps) {
    let {
      location: { pathname }
    } = this.props;

    if (prevProps.location.pathname === pathname) {
      return;
    }

    this.routeChanged();
  }

  routeChanged() {
    let currentPath = this.props.location.pathname;

    LogHelper.logRoute(currentPath);
  }

  render() {
    return null;
  }
}

export default withRouter(RouteChange);
