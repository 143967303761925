import MonarchHelper from "../../helpers/MonarchHelper";
import MonarchInputIndicator from "./MonarchInputIndicator";
import React, { Component } from "react";

const styles = {
  body: {
    height: "100%"
  },
  marginTop: {
    marginTop: "2em"
  },
  title: {
    fontSize: "1.2em",
    fontWeight: "bold",
    marginBottom: ".5em"
  },
  streamToggleButton: {
    marginTop: ".75em"
  },
  icon: {
    fontSize: ".8em",
    padding: ".25em"
  },
  buttonText: {
    textTransform: 'uppercase',
    fontSize: '.8em'
  },
  noBottomMargin: {
    marginBottom: "0"
  }
};

class Monarch extends Component {
  state = {
    failed: false,
    monarch: null,
    isLoading: true,
    shouldReload: false
  };

  handleStreamingToggle(e) {
    e.preventDefault();

    let id = this.state.monarch.guid;
    let { streaming } = this.state.monarch.status;

    this.setState({
      isLoading: true
    });

    if (streaming) {
      this.setState({
        shouldReload: true
      });
      MonarchHelper.stop(id).then(() => { });
    } else {
      MonarchHelper.start(id).then(() => {
        this.setState({
          shouldReload: true
        });
      });
    }
  }

  getStatus(id) {
    MonarchHelper.getOne(id)
      .then(monarch => {
        this.setState({
          monarch,
          isLoading: false,
          shouldReload: false
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          failed: true
        });
      });
  }

  handleReload(e) {
    e.preventDefault();

    this.setState({
      failed: false,
      monarch: null,
      isLoading: true,
      shouldReload: false
    });

    this.getStatus(this.props.id);
  }

  componentDidMount() {
    this.getStatus(this.props.id);
  }

  render() {
    if (this.state.shouldReload) {
      setTimeout(() => {
        this.getStatus(this.props.id);
      }, 5000);
    }

    let startButton = (
      <button
        style={styles.streamToggleButton}
        className="button is-success"
        onClick={this.handleStreamingToggle.bind(this)}
      >
        <span style={styles.buttonText}>Start</span>
      </button>
    );
    let stopButton = (
      <button
        style={styles.streamToggleButton}
        className="button is-danger"
        onClick={this.handleStreamingToggle.bind(this)}
      >
        <span style={styles.buttonText}>Stop</span>
      </button>
    );

    let waitButton = text => {
      return (
        <button style={styles.streamToggleButton} className="button is-dark">
          <i style={styles.icon} className="fas fa-spinner fa-spin"></i> <span style={styles.buttonText}>{text}</span>
        </button>
      );
    };

    let streamingButton = "";

    if (this.state.monarch !== null) {
      if (this.state.monarch.status.inputs.video !== "No video input") {
        if (this.state.monarch.status.streaming) {
          streamingButton = stopButton;
        } else {
          streamingButton = startButton;
        }

        if (this.state.isLoading) {
          let text = this.state.monarch.status.streaming
            ? "Stopping"
            : "Starting";

          streamingButton = waitButton(text);
        }
      }
    }

    return (
      <div className="notification" style={styles.body}>
        {this.props.hideHeader !== true && (
          <div className="level noBottomMargin">
            <div className="level-left">
              <div className="level-item">
                <p style={styles.title}>{this.props.title}</p>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                {this.state.monarch !== null && (
                  <a
                    target="_blank" rel="noopener noreferrer"
                    href={`${this.state.monarch.url}/Monarch/Control.aspx`}
                  >
                    <i className="fas fa-link" style={styles.icon} />
                  </a>
                )}

                <a href='/' onClick={this.handleReload.bind(this)}>
                  <i className="fas fa-sync-alt" style={styles.icon} />
                </a>
              </div>
            </div>
          </div>
        )}

        {this.state.isLoading && this.state.monarch === null && <p><i style={styles.icon} className="fas fa-spinner fa-spin"></i>Loading</p>}

        {this.state.failed && (
          <p className="has-text-danger">Unable to connect</p>
        )}

        {this.state.monarch !== null && (
          <div>
            <MonarchInputIndicator
              status={this.state.monarch.status.inputs.audio}
              type="audio"
            />
            <MonarchInputIndicator
              status={this.state.monarch.status.inputs.video}
              type="video"
            />
          </div>
        )}

        {streamingButton}
      </div>
    );
  }
}

export default Monarch;
