import MonarchHelper from "../../helpers/MonarchHelper";
import Monarch from './Monarch';
import React, { Component } from "react";

const styles = {
  marginTop: {
    marginTop: "2em"
  }
};

class Monarchs extends Component {
  state = {
    monarchs: []
  };

  componentDidMount() {
    MonarchHelper.getAll()
      .then(async monarchs => {
        let sortedMonarchs = monarchs.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });

        this.setState({
          monarchs: sortedMonarchs
        });
      })
      .catch(err => {
        if (err.message.indexOf("code 401") > -1) {
          this.setState({
            isLoading: false,
            isFailed: true
          })
        }
      });
  }

  render() {
    let noMonarchsWarning = "";

    if (this.state.isFailed) {
      noMonarchsWarning = (
        <div className="container" style={styles.marginTop}>
          <div className="notification is-danger">
            There was a problem retrieving Monarchs.  Please log out and try again.
          </div>
        </div>
      );
    }

    let monarchComponents = this.state.monarchs.map(monarch => {
      return (
        <div key={monarch.guid} className="column is-one-third">
          <Monarch id={monarch.guid} title={monarch.title} />
        </div>
      );
    });

    return (
      <div>
        <section className="hero is-dark">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">Monarch Control</h1>
              <h2 className="subtitle">Manage Monarch Devices</h2>
            </div>
          </div>
        </section>

        {noMonarchsWarning}

        <div className="container" style={styles.marginTop}>
          <div className="columns is-multiline">{monarchComponents}</div>
        </div>
      </div>
    );
  }
}

export default Monarchs;
