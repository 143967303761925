import config from "../../config";
import LiveHelper from "../../helpers/LiveHelper";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Player from "../Player";
import Logo from "../../assets/logo-white.svg";
import qs from "query-string";
import utils from "../../utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class LiveMultiView extends Component {
  state = {
    videos: [],
    isLoading: true,
    isNavbarVisible: true,
  };

  handleKeyUp = (e) => {
    if (e.keyCode === 27) {
      this.setState({
        isNavbarVisible: true,
      });
    }
  };

  refreshVideos() {
    LiveHelper.getAll()
      .then(async (videos) => {
        this.setState({
          videos,
          isLoading: false,
        });
      })
      .catch((err) => {
        if (err.message.indexOf("code 401") > -1) {
          this.setState({
            isLoading: false,
            isFailed: true,
          });
        }
      });
  }

  componentDidMount() {
    this.refreshVideos();

    let cols = qs.parse(this.props.location.search).cols || 3;

    this.setState({
      cols,
    });

    this.handleKeyUp = this.handleKeyUp.bind(this);
    document.addEventListener("keyup", this.handleKeyUp);

    this.interval = setInterval(() => {
      this.checkForNewVideos();
    }, config.liveVideoRefreshCheck);
  }

  componentWillMount() {
    // Set the background color
    document.getElementsByTagName("html")[0].style.backgroundColor = "#111";
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleKeyUp);

    // Reset the background color
    document.getElementsByTagName("html")[0].style.backgroundColor = "";

    clearInterval(this.interval);
  }

  handleReload = (e) => {
    // Force a render without state change...
    window.location.reload();
  };

  handleHideNavbar = (e) => {
    e.preventDefault();

    this.setState({
      isNavbarVisible: false,
    });

    toast.info("Press the ESC key to see the navigation bar again.", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  checkForNewVideos() {
    // Check for new videos, and refresh if the counts are different
    LiveHelper.getAll()
      .then(async (videos) => {
        let currentVideos = this.state.videos.map((video) => {
          return video.guid;
        });

        let newVideos = videos.map((video) => {
          return video.guid;
        });

        let areEqual = utils.arraysEqual(currentVideos, newVideos);

        if (!areEqual) {
          this.setState({
            videos,
            isLoading: false,
          });
        }
      })
      .catch((err) => {
        if (err.message.indexOf("code 401") > -1) {
          this.setState({
            isLoading: false,
            isFailed: true,
          });
        }
      });
  }

  render() {
    let styles = {
      paddingTop: {
        paddingTop: "2em",
      },
      title: {
        color: "#999",
      },
      videoBox: {
        padding: ".1em",
      },
      contentBox: {
        marginTop: "1px",
      },
      smallMarginRight: {
        marginRight: ".25em",
      },
    };

    let columnClassName = this.state.cols == 3 ? "is-one-third" : "is-half";

    let sourceComponents = this.state.videos.map((video) => {
      return (
        <div
          key={video.guid}
          className={`column ${columnClassName}`}
          style={styles.videoBox}
        >
          <Player
            key={video.guid}
            title={video.title}
            url={video.url}
            type={video.type}
            muted={true}
          />
        </div>
      );
    });

    let noVideoWarning = (
      <div className="container" style={styles.paddingTop}>
        <div className="notification is-warning">
          There are no live views available at this time.
        </div>
      </div>
    );

    if (this.state.isFailed) {
      noVideoWarning = (
        <div className="container" style={styles.paddingTop}>
          <div className="notification is-danger">
            There was a problem retrieving live views. Please log out and try
            again.
          </div>
        </div>
      );
    }

    let loading = (
      <div className="container" style={styles.paddingTop}>
        <div className="notification is-dark">Loading...</div>
      </div>
    );

    return (
      <div>
        {this.state.isNavbarVisible && (
          <nav
            className="navbar has-background-black"
            role="navigation"
            aria-label="main navigation"
          >
            <div className="navbar-brand">
              <Link className="navbar-item" to="/home">
                <img src={Logo} width="112" height="28" alt="" />
              </Link>

              <div className="navbar-item">
                <span style={styles.title}>Live Campus Multi View</span>
              </div>
              <div
                className="navbar-item"
                style={{
                  position: "absolute",
                  right: "0",
                }}
              >
                <button
                  onClick={this.handleHideNavbar}
                  className="button is-dark"
                  style={styles.smallMarginRight}
                >
                  <i className="fas fa-expand-arrows-alt" />
                </button>

                <button onClick={this.handleReload} className="button is-dark">
                  <i className="fas fa-sync-alt" />
                </button>
              </div>
            </div>
          </nav>
        )}

        {this.state.isLoading && loading}

        {!this.state.isLoading &&
          this.state.videos.length === 0 &&
          noVideoWarning}

        <div
          className="columns is-multiline is-1 is-variable"
          style={styles.contentBox}
        >
          {sourceComponents}
        </div>

        <ToastContainer />
      </div>
    );
  }
}

export default LiveMultiView;
