import React, { Component } from "react";

const styles = {
  marginTop: {
    marginTop: '2em'
  }
}

class App extends Component {
  render() {
    return (
      <div>
        <section className="hero is-dark">
          <div className="hero-body">
            <div className="container">
              <h1 className="title">404</h1>
            </div>
          </div>
        </section>

        <div className="container" style={styles.marginTop}>
          <div className="content notification">
            <p>
              Sorry, but the page you're looking for cannot be found!
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
