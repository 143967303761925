import AuthenticationHelper from "../../helpers/AuthenticationHelper";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Logo from '../../assets/logo-white.svg';

class Login extends Component {
  state = {
    redirectToHome: false,
    username: '',
    password: '',
    invalidLoginMessage: ""
  };

  handleLogin = async e => {
    e.preventDefault();

    const { username, password } = this.state;

    try {
      await AuthenticationHelper.login(username, password);

      // TODO: Only do this if successful.  Otherwise, set error state for error message.
      this.setState({
        redirectToHome: true
      });
    } catch (err) {
      let message = 'Error authenticating!';

      if(err.message.indexOf('401')) {
        message = 'Invalid Username and/or Password'
      }

      this.setState({
        invalidLoginMessage: message,
        username: '',
        password: ''
      });
    }
  };

  handleUsernameChange = e => {
    this.setState({
      username: e.target.value
    });
  };

  handlePasswordChange = e => {
    this.setState({
      password: e.target.value
    });
  };

  handleKeyUp = e => {
    if (e.key === "Enter") {
      this.handleLogin(e);
    }
  };

  render() {
    let { redirectToHome } = this.state;

    if (AuthenticationHelper.isAuthenticated()) {
      redirectToHome = true;
    }

    if (redirectToHome === true) {
      return <Redirect to="/home" />;
    }

    return (
      <section className="hero is-fullheight is-dark is-bold">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-4 is-offset-4">
                <img id="logo" src={Logo} alt="Logo" />
                <div className="box">
                  <div v-if="loginFailed" className="has-text-danger">
                    {this.state.invalidLoginMessage}
                  </div>
                  <label className="label">Username</label>
                  <p className="control">
                    <input
                      value={this.state.username}
                      onChange={this.handleUsernameChange}
                      id="usernameField"
                      className="input"
                      v-model="username"
                      type="username"
                      placeholder="Username"
                      autoCapitalize="none"
                      autoCorrect="off"
                      onKeyUp={this.handleKeyUp}
                      required
                      autoFocus
                    />
                  </p>
                  <label className="label">Password</label>
                  <p className="control">
                    <input
                      value={this.state.password}
                      onChange={this.handlePasswordChange}
                      id="passwordField"
                      className="input"
                      v-model="password"
                      type="password"
                      placeholder="Password"
                      autoCapitalize="none"
                      autoCorrect="off"
                      onKeyUp={this.handleKeyUp}
                      required
                    />
                  </p>
                  <br />
                  <p className="control">
                    <button
                      id="login"
                      className="button is-dark"
                      onClick={this.handleLogin}
                    >
                      Sign in
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Login;
