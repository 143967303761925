import RecordingHelper from "../../helpers/RecordingHelper";
import React, { Component } from "react";
import Player from "../Player";
import qs from "query-string";

import Header from "../Common/Header";
import Footer from "../Common/Footer";

const styles = {
  marginTop: {
    marginTop: "2em"
  },
  site: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column"
  },
  siteContent: {
    flex: 1
  },
  footer: {
    marginTop: "2em"
  }
};

class App extends Component {
  state = {
    isLoading: true,
    isFailed: false,
    recording: {}
  };

  componentDidMount() {
    let { id } = this.props.match.params;

    RecordingHelper.getOnePublic(id)
      .then(recording => {
        if (recording !== null) {
          this.setState({
            recording,
            isLoading: false
          });
        } else {
          this.setState({
            recording: {},
            isLoading: false,
            isFailed: true
          });
        }
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          isFailed: true
        });
      });
  }

  render() {
    let at = qs.parse(this.props.location.search).at;

    let {
      formattedDate: date,
      guid,
      title,
      type,
      video_url: url
    } = this.state.recording;

    let noVideoWarning = "";

    if (this.state.isFailed) {
      title = "";
      date = "";

      noVideoWarning = (
        <div className="container" style={styles.marginTop}>
          <div className="notification is-danger">
            There was a problem retrieving this video.
          </div>
        </div>
      );
    }

    let isVideoObjectEmpty =
      Object.keys(this.state.recording).length === 0 &&
      this.state.recording.constructor === Object;

    let loading = (
      <div className="container" style={styles.marginTop}>
        <div className="notification is-primary">Loading...</div>
      </div>
    );

    return (
      <div style={styles.site}>
        <div style={styles.header}>
          <Header
            showHome={false}
            showRecordings={false}
            showReload={false}
            showLogout={false}
            forcedToggle={true}
          />
        </div>

        <div style={styles.siteContent}>
          <section className="hero is-dark">
            <div className="hero-body">
              <div className="container">
                <h1 className="title">{title}</h1>
                <h2 className="subtitle">{date}</h2>
              </div>
            </div>
          </section>

          {noVideoWarning}

          {this.state.isLoading && loading}

          {!this.state.isFailed && (
            <div className="container" style={styles.marginTop}>
              {!isVideoObjectEmpty && (
                <Player key={guid} url={url} type={type} at={at} />
              )}
            </div>
          )}
        </div>

        <div style={styles.footer}>
          <Footer style={styles.footer} username="public" />
        </div>
      </div>
    );
  }
}

export default App;
